.display-4 {
  text-align: center;
  font-size: 3em;
  font-weight: 200 !important;
  margin: 0;
  pointer-events: none;
}

.display-5 {
  text-align: center;
  font-size: 2em;
  font-weight: 100;
  pointer-events: none;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

.modal-header {
  margin: auto;
  width: 100%;
}

.modal-content {
  border: none !important;
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.MuiCollapse-wrapper {
    margin-top: 6px;
    margin-bottom: 6px;
}