.fc-theme-standard .fc-popover {
    background-color: #4B647D;
    border-radius: 0.25em;
}

.fc-more-popover {
    max-height: 50%;
    overflow-y: scroll;
}

.fc .fc-scrollgrid-section-sticky > * {
    background: none !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: 1px solid rgba(152, 152, 152, 0.29) !important;
}

.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid rgba(152, 152, 152, 0.29) !important;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 150px !important;
}